<template>
    <vue-dropzone
        ref="myVueDropzone" id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-success="handleSuccess"
        @vdropzone-error="handleError"
        @vdropzone-drop="handleDrop"/>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Cropper from 'cropperjs'
    import 'cropperjs/dist/cropper.min.css'
    import {mapMutations} from "vuex";

    export default {
        name: "avatar-edit",
        components: {
            vueDropzone: vue2Dropzone
        },
        props: ['current_url'],
        data: function () {
            return {
                dropzoneOptions: {
                    url: '/api/upload',
                    maxFilesize: 2,
                    headers: {
                        Authorization: null
                    },
                    transformFile: function (file, done) {
                        const myDrop = this;
                        let editor = document.createElement("div");
                        editor.className = "editor";

                        let confirm = document.createElement("button");
                        confirm.className = "confirm"
                        confirm.textContent = "CONFIRM"
                        confirm.addEventListener('click', () => {
                            myDrop.removeAllFiles()
                            let canvas = cropper.getCroppedCanvas({
                                width: 256,
                                height: 256
                            });
                            canvas.toBlob((blob) => {
                                myDrop.createThumbnail(
                                    blob,
                                    myDrop.options.thumbnailWidth,
                                    myDrop.options.thumbnailHeight,
                                    myDrop.options.thumbnailMethod,
                                    false,
                                    (dataURL) => {
                                        myDrop.emit('thumbnail', file, dataURL);
                                        done(blob);
                                    }
                                );
                            });
                            document.body.removeChild(editor);
                        });
                        let image = new Image();
                        image.src = URL.createObjectURL(file);
                        editor.appendChild(confirm);
                        editor.appendChild(image);
                        document.body.appendChild(editor);
                        let cropper = new Cropper(image, { aspectRatio: 1 });
                    },
                },
            }
        },
        methods: {
            handleSuccess(file, res) {
                this.$emit('input', res.url);
            },
            handleDrop() {
                this.$refs.myVueDropzone.removeAllFiles();
            },
            handleError(file) {
                this.showSnackBar({color: 'error', timeout: 3000, text: file.previewTemplate.innerText});
                this.$refs.myVueDropzone.removeFile(file);
            },
            ...mapMutations(['showSnackBar']),
        },
        watch: {
            current_url() {
                if (this.current_url === "updatePersonalization") {
                    document.getElementsByClassName("dropzone")[0].click();
                }
                else if (this.current_url === "updateAvatar") {
                    document.getElementsByClassName("dropzone")[1].click();
                }
                else if (this.current_url) {
                    this.$refs.myVueDropzone.removeAllFiles();
                    let file = {
                        size: 123,
                        name: this.current_url,
                        url: this.current_url,
                        thumbnail: this.current_url,
                    };
                    console.log(file)
                    this.$refs.myVueDropzone.manuallyAddFile(file, this.current_url);
                } else {
                    this.$refs.myVueDropzone.removeAllFiles();
                }
            },
        },
        async mounted() {
            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer "+token
        }
    }
</script>

<style>
    .editor {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background-color: #000;
    }

    *, ::before, ::after {
        background-repeat: inherit;
    }

    .confirm {
        position: relative;
        font-size: 90%;
        font-weight: 700;
        color: rgb(209,209,217);
        text-decoration: none;
        text-shadow: 0 -1px 2px rgba(0,0,0,.2);
        padding: .5em 1em;
        outline: none;
        border-radius: 3px;
        background: linear-gradient(rgb(110,112,120), rgb(81,81,86)) rgb(110,112,120);
        box-shadow:
            0 1px rgba(255,255,255,.2) inset,
            0 3px 5px rgba(0,1,6,.5),
            0 0 1px 1px rgba(0,1,6,.2);
        transition: .2s ease-in-out;
    }

    .confirm:hover:not(:active) {
        background: linear-gradient(rgb(126,126,134), rgb(70,71,76)) rgb(126,126,134);
    }
    .confirm:active {
        top: 1px;
        background: linear-gradient(rgb(76,77,82), rgb(56,57,62)) rgb(76,77,82);
        box-shadow:
            0 0 1px rgba(0,0,0,.5) inset,
            0 2px 3px rgba(0,0,0,.5) inset,
            0 1px 1px rgba(255,255,255,.1);
    }

    .dz-clickable.dz-started {
        text-align: center;
    }

    .dropzone .dz-preview .dz-details .dz-size {
        display: none;
    }

    .dropzone .dz-preview .dz-details .dz-filename {
        display: none;
    }

    .dz-preview .dz-image {
        z-index: 4 !important;
    }

    .vue-dropzone>.dz-preview .dz-details {
        background-color: rgba(33,150,243,.1) !important;
    }
</style>
